

.swiper-container {
  width: 800px;
  height: 600px;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
  color: rgb(255, 255, 255, 0.3);
  cursor: pointer;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
  color: rgb(255, 255, 255, 0.3);
  cursor: pointer;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  width: 200px;
  top: 16px;
  left: 110px;
}
.swiper-pagination-current{
  color: #FF5A3E;
}
.swiper-pagination {
  color: aliceblue;
  font-size: 16px
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
