@import "./normalize.css";

/* 样式的重置 */
body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #666;
}

a:hover {
  color: #666;
  text-decoration: underline;
}

i,
em {
  font-style: normal;
}

input,
textarea,
button,
select,
a {
  outline: none;
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: none;
  vertical-align: middle;
}

html {
  font-size: 67.5%;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  margin: 0 auto;
  /* min-width: 1890px !important; */
  position: relative;
}

